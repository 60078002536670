﻿.container {
	padding: 0 rem(20px);

	&.-small {

		@include desktop {
			max-width: rem(576px);
		}
	}

	&.-padding {
		padding: rem(72px) rem(20px) rem(62px);
	}
}
