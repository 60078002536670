﻿.footer-images {
	margin: 0 -20px 9px;
	display: flex;
	flex-wrap: wrap;

	.footer-image {
		margin: 20px;
		padding: 10px;
		background: #fff;

		img {
			vertical-align: middle;
		}
	}
}
