﻿@import '~bulma/sass/elements/content';


.content {

	.subtitle {
		color: $text;
		margin-bottom: rem(5px);
	}

	table {
		margin: 0 0 rem(14px);

		td {
			padding: 0 rem(20px) 0 0;
			border-width: 0;
			min-width: 200px !important;
			background: transparent !important;

			&:last-child {
				padding: 0;
			}
		}
	}

	.warningrtf-title {
		font-weight: bold;
	}

	a:not(.button) {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	&.is-rsa-news-item {

		img {
			display: none;
		}
	}
}