﻿$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 30px;
$hamburger-layer-height: 3px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: $primary;
$hamburger-layer-border-radius: 0;
$hamburger-hover-opacity: 1;
$hamburger-hover-transition-duration: 0.3s;
$hamburger-hover-transition-timing-function: ease-in-out;

@import "~hamburgers/_sass/hamburgers/hamburgers";

.hamburger {
	display: block;
	z-index: 1001;
	position: fixed;
	height: 60px;
	width: 60px;
	padding: 0 10px;
	margin: 0 0 0 rem(-30px);
	border: 1px solid rgba(#000, 0.1);
	border-radius: 50%;
	left: 50%;
	bottom: 40px;
	cursor: pointer;
	background: #fff;
	color: $primary;
	opacity: 1;
	transition: all 0.3s ease-in-out;
	font-weight: 400;

	@include desktop {
		margin: 0;
		top: 50px;
		right: 50px;
		left: auto;
		display: none;
	}

	&.is-active {
		display: block !important;
	}

	.hamburger-box {
		top: 4px;
	}

	.hamburger-inner,
	.hamburger-inner:before,
	.hamburger-inner:after {
		transition: all 0.3s ease-in-out;
	}

	&.hamburger--spin.is-active .hamburger-inner::after {
		transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), background 0.3s ease-in-out;
	}

	&:hover {
		background: $primary;

		.hamburger-inner,
		.hamburger-inner:before,
		.hamburger-inner:after {
			background: #fff;
		}
	}

	$colors: "purple" $purple, "yellow" $yellow, "blue" $blue;

	@each $name, $color in $colors {
		.#{$name} & {

			.hamburger-inner,
			.hamburger-inner:before,
			.hamburger-inner:after {
				background: $color;
			}

			&:hover {
				background: $color;

				.hamburger-inner,
				.hamburger-inner:before,
				.hamburger-inner:after {
					background: #fff;
				}
			}
		}
	}
}