﻿$grey-darker: $primary;
$title-family: $family-title;
$title-line-height: 1em;

$subtitle-color: #555657;

@import '~bulma/sass/elements/title';

.title {

	$colors: "purple" $purple, "yellow" $yellow, "blue" $blue;

	@each $name, $color in $colors {
		.#{$name} & {
			color: $color;
		}
	}

	small {
		display: block;
		font-size: 14px;
		line-height: 1.3em;
		color: $text;
		font-weight: normal;
		margin: rem(10px) 0 0;
	}
}