﻿$breadcrumb-item-color: $primary-alt;
$breadcrumb-item-hover-color: $primary-alt;
$breadcrumb-item-active-color: $primary-alt;

@import '~bulma/sass/components/breadcrumb';

.breadcrumb {
	text-transform: uppercase;
	font-weight: 500;

	li,
	li a {
		color: $primary-alt;
	}

	li + li:before {
		@include icon($icon-arrow-right);
		color: $primary-alt;
		padding: 0 rem(10px) 0 0;
		font-size: rem(8px);

	}

	a:hover {
		text-decoration: underline;
	}

	$colors: "purple" $purple-alt, "yellow" $yellow-alt, "blue" $blue-alt;

	@each $name, $color-alt in $colors {
		.#{$name} & {

			li,
			li a {
				color: $color-alt;
			}

			li + li:before {
				color: $color-alt;
			}
		}
	}
}