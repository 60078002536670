﻿.news:not(.columns) {
	margin: 0 0 rem(9px);
}

.news {

	li {
		margin: 0 0 rem(21px);
	}

	a {
		overflow: hidden;

		&:hover .subtitle:before {
			right: rem(-22px);
			opacity: 1;
		}
	}

	.title {
	}

	.subtitle {
		position: relative;
		z-index: 0;
		display: inline-block;
		font-size: rem(15px);
		font-weight: normal;

		&:before {
			@include icon($icon-arrow-list);
			position: absolute;
			z-index: -1;
			right: 0;
			font-size: rem(18px);
			opacity: 0;
			transition: all $transition-speed ease-in-out;
		}
	}


}

.news-item {
	display: flex;
	flex-wrap: wrap;
	background: #fff;
	transition: background 0.2s ease-in-out;
	padding: rem(20px) 0 0;

	@media only screen and (min-width: 520px) {
		flex-wrap: initial;
		padding: 0;
	}

	&:hover {
		background: mix($primary, #fff, 15%);
	}

	img {
		width: rem(250px);
		height: rem(167px);
		object-fit: cover;
		object-position: center;
		flex-shrink: 0;
		margin: 0 auto;

		@media only screen and (min-width: 520px) {
			margin: 0;
		}
	}

	.news-item__content {
		flex-shrink: 1;
		padding: rem(20px);
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		text-align: center;

		@media only screen and (min-width: 520px) {
			text-align: left;
			padding: rem(40px);
		}
	}

	.news-item__text {
	}

	.title:not(.is-spaced) + .subtitle {
		margin: 0;
	}

	.subtitle:before {
		content: '';
	}
}