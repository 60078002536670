﻿$label-color: $primary;
$label-weight: $weight-bold !default;

@import '~bulma/sass/form/tools';

.label {
	font-size: rem(16px);
	margin: 0;

	&:not(:last-child) {
		margin-bottom: rem(-1px);
	}

	$colors: "purple" $purple $purple-alt $purple-light, "yellow" $yellow $yellow-alt $yellow-light, "blue" $blue $blue-alt $blue-light;

	@each $name, $color, $color-alt, $color-light in $colors {
		.#{$name} & {
			color: $color;
		}
	}
}