﻿@import '~bulma/sass/elements/image';

.image {

	&.play a {

		&:before {
			@include icon($icon-play);
			position: absolute;
			z-index: 1;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: rem(112px);
			color: #fff;
			transition: all $transition-speed ease-in-out;
		}

		&:hover {

			&:before {
				color: $primary;
			}
		}
	}

	&.ctc {

		&:after {
			position: absolute;
			content: '';
			width: rem(383px);
			height: 100%;
			top: 0;
			left: 0;
			background: url(../../../images/primary/overlay-primary.png) repeat;
		}

		@include tablet {
			padding-top: 0;
		}

		@include desktop {
			padding-top: (2 / 3 * 100%);
		}

		@include widescreen {
			padding-top: 0;
		}
	}

	&.placeholder {
		background: url(../../../images/primary/placeholder-primary.jpg);
		background-size: cover;
	}

	&.fill-container {
		position: relative;
		height: 100%;

		img {
			position: absolute;
			width: 100%;
			min-height: rem(510px);
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;

			&.-contain {
				object-fit: contain;
			}
		}
	}

	&.highlighted {
		position: relative;
		min-width: calc(100% + 2.5rem);
		left: rem(-20px);

		@include desktop {
			min-width: calc(100% + 20px);
			width: calc(100% + ((100vw - 1344px) / 2));
			left: 0;
		}
	}

	+ .content {
		margin-top: rem(31px);
	}

	$colors: "purple" $purple, "yellow" $yellow, "blue" $blue;

	@each $name, $color in $colors {
		.#{$name} & {

			&.placeholder {
				background-image: url(../../../images/#{$name}/placeholder-#{$name}.jpg);
			}

			&.ctc {

				&:after {
					background: url(../../../images/#{$name}/overlay-#{$name}.png) repeat;
				}
			}

			&.play a {

				&:hover {

					&:before {
						color: $color;
					}
				}
			}
		}
	}
}