﻿$primary: #007d75;
$primary-light: mix($primary, #fff, 10%);
$primary-alt: #00d7c9;
$primary-alt-light: mix($primary-alt, #fff, 20%);

$blue-light: mix($blue, #fff, 10%);
$blue-alt: #38c2ff;
$blue-alt-light: mix($primary-alt, #fff, 20%);

$yellow-light: mix($yellow, #fff, 10%);
$yellow-alt: #d2bc61;
$yellow-alt-light: mix($yellow-alt, #fff, 20%);

$purple-light: mix($purple, #fff, 10%);
$purple-alt: #c493be;
$purple-alt-light: mix($purple-alt, #fff, 20%);

// Text colors

$text: #555657;
$link: $text;

// Typgrofy

$pre-code-font-size: 2em;

@import '~bulma/sass/utilities/derived-variables';