﻿$column-gap: rem(20px);

@import '~bulma/sass/grid/columns';

.columns:not(:last-child),
.columns.is-gapless:not(:last-child) {
	margin-bottom: 0;
}

.columns {

	&.is-widescreen {
		display: block !important;

		@include widescreen {
			display: flex !important;
		}
	}
}


.column {

	&.-image {

		@include widescreen {
			padding-left: rem(92px);
		}

		&.-left {
			order: -1;

			@include widescreen {
				padding-right: rem(92px);
				padding-left: rem(20px);
			}
		}
	}

	&.valign-content-center {
		display: flex;
		flex-wrap: wrap;
		align-content: center;
	}
}	