﻿$input-radius: 0;
$input-border-color: #fff;
$input-height: rem(48px);

@import '~bulma/sass/form/shared';

.input {
	min-height: $input-height;
	color: $text;
}

.umbraco-forms-page {

	.button {
		margin-top: rem(-40px);
	}
}