﻿@import "~swiper/dist/css/swiper.css";

.slide {
	position: relative;

	&__text {
		position: absolute;
		z-index: 1;
		width: 100%;
		padding: rem(32px) rem(20px) rem(80px);
		bottom: 0;
		left: 0;
		background: rgba($primary, 0.8);
		box-sizing: border-box;

		.title {
			color: #fff;
			max-width: rem(480px);
			margin:  0 auto rem(33px);
		}
	}

	img {
		width: 100%;
	}
}

.swiper-button-prev,
.swiper-button-next {
	background: rgba($primary-alt, 0.5);
	color: $primary;
	width: rem(40px);
	height: rem(48px);
	margin: 0;
	top: auto;
	bottom: rem(114px);
	transition: all $transition-speed ease-in-out;

	&:before {
		position: absolute;
		font-size: rem(15px);
		top: 50%;
		transform: translateY(-50%);
	}

	&:hover {
		background: $primary;
		color: $primary-alt;
	}
}

.swiper-button-prev {
	left: 0;
	border-radius: 0 rem(24px) rem(24px) 0;

	&:before {
		@include icon($icon-arrow-left);
		left: rem(10px);
	}
}

.swiper-button-next {
	right: 0;
	border-radius: rem(24px) 0 0 rem(24px);

	&:before {
		@include icon($icon-arrow-right);
		right: rem(10px);
	}
}

.swiper-container-horizontal > .swiper-pagination-bullets {
	bottom: rem(25px);

	.swiper-pagination-bullet {
		background: #fff;
		width: rem(10px);
		height: rem(10px);
		opacity: 1;
		margin: 0 rem(5px);

		&.swiper-pagination-bullet-active {
			background: $primary-alt;
		}
	}
}