﻿.notification-bar {
	display: block;
	background: #00d7c9;
	text-align: center;
	padding: 0.5rem 0;
	color: #fff;
	font-size: 1.125rem;

	span {
		position: relative;
		z-index: 0;

		&:before {
			content: "";
			position: absolute;
			z-index: -1;
			width: 100%;
			height: 2px;
			left: 0;
			bottom: 0;
			font-size: 1.625rem;
			opacity: 0;
			transition: all .25s ease-in-out;
			background: #fff;
		}
	}

	&:hover {
		color: #fff;

		span {

			&:before {
				opacity: 1;
			}
		}
	}

	$colors: "purple" $purple $purple-alt $purple-light, "yellow" $yellow $yellow-alt $yellow-light, "blue" $blue $blue-alt $blue-light;

	@each $name, $color, $color-alt, $color-light in $colors {
		.#{$name} & {
			background: $color-alt;

			.title {
				color: #fff;

				strong {
					color: $color;
				}
			}
		}
	}
}
