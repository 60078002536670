﻿$textarea-min-height: rem(138px);

@import '~bulma/sass/form/input-textarea';

.textarea {
	min-height: $textarea-min-height;
	color: $text;
}

.umbraco-forms-field.longanswer {

	label {
		width: 100%;
	}

	.control {
		width: 100%;

		textarea {

			.contactform & {
				min-height: rem(411px);
			}
		}
	}
}