﻿.call-to-action {
	position: relative;
	background: $primary-alt;
	overflow: hidden;

	.title {
		color: #fff;

		strong {
			color: $primary;
		}
	}

	.content {
		padding: rem(44px) 0 rem(52px);
	}

	.columns {

		@include tablet {
			min-height: rem(512px);
		}

		@include desktop {

			.column {
				width: 100%;
			}
		}

		@include widescreen {

			.column {
				width: 50%;
			}
		}
	}

	.image {

		@include tablet {
			position: absolute;
			width: 50%;
			height: 100%;
			top: 0;
			right: 0;
		}

		@include desktop {
			position: relative;
			width: 100%;
			height: auto;
			top: initial;
			left: initial;
		}

		@include widescreen {
			position: absolute;
			width: 50%;
			height: 100%;
			top: 0;
			right: 0;
		}
	}

	$colors: "purple" $purple $purple-alt $purple-light, "yellow" $yellow $yellow-alt $yellow-light, "blue" $blue $blue-alt $blue-light;

	@each $name, $color, $color-alt, $color-light in $colors {
		.#{$name} & {
			background: $color-alt;

			.title {
				color: #fff;

				strong {
					color: $color;
				}
			}
		}
	}
}
