﻿// Colors

$yellow: #967D16;
$blue: #0074A7;
$purple: #864F7F;

// Typography

$family-sans-serif: 'Roboto', sans-serif;
$family-title: 'RobotoSlab Regular', sans-serif;

$size-1: rem(56px);
$size-2: rem(48px);
$size-3: rem(40px);
$size-4: rem(32px);
$size-5: rem(24px);
$size-6: rem(18px);
$size-7: rem(16px);

// Miscellaneous

$radius: 24px;
$transition-speed: 0.25s;
$gap: 20px;

@import '~bulma/sass/utilities/initial-variables';
