﻿$section-padding: rem(72px) 0 rem(62px);
//$section-padding-medium: 9rem 1.5rem !default
//$section-padding-large: 18rem 1.5rem !default

@import '~bulma/sass/layout/section';

.section {
	position: relative;
	overflow: hidden;

	&:nth-of-type(odd) {
		background: $primary-light;
	}

	.section-padding {
		padding: $section-padding;
	}

	&.is-highlighted {
		background: $primary;
		padding: 0;

		.title {
			color: $primary-alt;
		}

		.content {
			color: #fff;
		}
	}

	&.has-background-color {
		background: $primary-alt;
	}

	$colors: "purple" $purple $purple-alt $purple-light, "yellow" $yellow $yellow-alt $yellow-light, "blue" $blue $blue-alt $blue-light;

	@each $name, $color, $color-alt, $color-light in $colors {
		.#{$name} & {

			&:nth-of-type(odd) {
				background: $color-light;
			}

			&.is-highlighted {
				background: $color;

				.title {
					color: $color-alt;
				}
			}

			&.has-background-color {
				background: $color-alt;
			}
		}
	}
}