﻿.legal {
	position: relative;
	padding: 0 0 rem(140px);
	margin: 0;
	overflow: hidden;
	background: rgba(#000, 0.1);
	color: $primary-alt;
	font-size: rem(13px);
	font-family: $family-sans-serif;

	@include desktop {
		padding: 0;
	}

	&__list {
		overflow: hidden;
		list-style: none;
		margin: 0;
		padding: rem(9px) 0 rem(7px);
	}

	li {
		float: left;
		padding-top: rem(4px);
		padding-bottom: rem(4px);
		margin-right: rem(20px);
		text-transform: none;
	}

	a {
		text-decoration: none;
		color: inherit;

		&:hover {
			text-decoration: underline;
		}
	}

	$colors: "purple" $purple $purple-alt, "yellow" $yellow $yellow-alt, "blue" $blue $blue-alt;

	@each $name, $color, $color-alt in $colors {
		.#{$name} & {
			color: $color-alt;
		}
	}
}
