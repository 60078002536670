﻿$menu-item-color: $primary;
$menu-item-hover-color: $primary;
$menu-item-hover-background-color: $primary-light;
$menu-item-active-color: $primary;
$menu-item-active-background-color: $primary-alt-light;

@import '~bulma/sass/components/menu';

.menu {
	width: 100%;
	text-align: center;
	font-size: rem(20px);
	font-family: $family-title;

	a {
		position: relative;
		display: inline-block;
		width: 100%;
		max-width: rem(260px);
		padding: rem(15px) 0 rem(16px);
		transition: all $transition-speed ease-in-out;

		&:before {
			position: absolute;
			content: '';
			width: 100%;
			height: 1px;
			top: 0;
			left: 0;
			background: linear-gradient(to right, rgba($primary,0.2) 0%, rgba($primary,1) 50%, rgba($primary,0) 100%);
			opacity: 0.2;
		}
	}

	li:last-child a:after {
		position: absolute;
		content: '';
		width: 100%;
		height: 1px;
		bottom: 0;
		left: 0;
		background: linear-gradient(to right, rgba($primary,0.2) 0%, rgba($primary,1) 50%, rgba($primary,0) 100%);
		opacity: 0.2;
	}

	$colors: "purple" $purple $purple-light $purple-alt-light, "yellow" $yellow $yellow-light $yellow-alt-light, "blue" $blue $blue-light $blue-alt-light;

	@each $name, $color, $color-light, $color-alt-light in $colors {
		.#{$name} & {

			a {
				color: $color;

				&:hover {
					color: $color;
					background: $color-light;
				}

				&.is-active {
					color: $color;
					background: $color-alt-light;
				}

				&:before {
					background: linear-gradient(to right, rgba($color,0.2) 0%, rgba($color,1) 50%, rgba($color,0) 100%);
				}
			}

			li:last-child a:after {
				background: linear-gradient(to right, rgba($color,0.2) 0%, rgba($color,1) 50%, rgba($color,0) 100%);
			}
		}
	}
}