@import '~bulma/sass/form/select';

.select {
	width: 100%;

	select {
		height: 100%;
		padding: 0 rem(30px);
		font-weight: 400;
	}
}