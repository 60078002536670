﻿@import '~bulma/sass/elements/button';

@mixin button-hover {
	background: $primary;
	color: $primary-alt;

	$colors: "purple" $purple $purple-alt $purple-light, "yellow" $yellow $yellow-alt $yellow-light, "blue" $blue $blue-alt $blue-light;

	@each $name, $color, $color-alt, $color-light in $colors {
		.#{$name} & {
			background: $color;
			color: $color-alt;
		}
	}
}

.button {
	position: relative;
	font-family: $family-sans-serif;
	z-index: 0;
	text-transform: uppercase;
	border-radius: $radius;
	padding: rem(12px) rem(30px) rem(11px);
	background: $primary-alt;
	color: $primary;
	font-size: rem(16px);
	font-weight: 500;
	height: auto;
	transition: all $transition-speed ease-in-out;
	border: none;
	white-space: initial;

	&:hover {
		@include button-hover;
	}

	&.-light {
		background: $primary-light;
	}

	&.-dark {
		color: $primary-alt;
		background: $primary;

		&:hover {
			color: $primary;
			background: $primary-alt;
		}
	}

	&.-inverted {
		background: none;
		padding-left: 0;
		padding-right: 0;
		color: $primary;

		&:before {
			content: '';
			position: absolute;
			z-index: -1;
			width: 100%;
			height: 2px;
			left: 0;
			bottom: rem(12px);
			font-size: rem(26px);
			opacity: 0;
			transition: all $transition-speed ease-in-out;
			background: $primary;
		}

		&.-light {
			color: $primary-alt;

			&:before {
				background: $primary-alt;
			}
		}

		&:hover {

			&:before {
				opacity: 1;
			}
		}
	}

	&.-white {
		background: #fff;
		color: $primary;
		box-shadow: 0px 10px 20px 0px rgba($primary, 0.2);

		&:hover {
			background: $primary;
			color: #fff;
		}
	}

	&.-floating {
		position: absolute;
		top: rem(40px);
		right: rem(40px);
	}

	&.-icon {
		position: relative;
		padding-left: rem(56px);

		&:before {
			@include icon($icon-person);
			position: absolute;
			top: 50%;
			left: rem(30px);
			transform: translateY(-50%);
			font-size: rem(16px);
		}
	}

	$colors: "purple" $purple $purple-alt $purple-light, "yellow" $yellow $yellow-alt $yellow-light, "blue" $blue $blue-alt $blue-light;

	@each $name, $color, $color-alt, $color-light in $colors {
		.#{$name} & {
			background: $color-alt;
			color: $color;

			&.-light {
				background: $color-light;
			}

			&.-dark {
				color: $color-alt;
				background: $color;

				&:hover {
					color: $color;
					background: $color-alt;
				}
			}

			&.-inverted {
				background: none;
				color: $color;

				&:before {
					background: $color;
				}

				&.-light {
					color: $color-alt;

					&:before {
						background: $color-alt;
					}
				}
			}

			&.-white {
				color: $color;
				box-shadow: 0px 10px 20px 0px rgba($color, 0.2);

				&:hover {
					background: $color;
				}
			}
		}
	}
}



.content .buttons {
	list-style: none;
	margin: 0 rem(-9px);

	li {
		margin: 0 rem(9px);
	}
}