﻿$icon-arrow-down: "\ea01";
$icon-arrow-left: "\ea02";
$icon-arrow-list: "\ea03";
$icon-arrow-right: "\ea04";
$icon-check: "\ea05";
$icon-person: "\ea06";
$icon-play: "\ea07";

@at-root {
    @font-face {
        font-family: icon;
        
        src: url("../../fonts/icon.woff2") format("woff2"), url("../../fonts/icon.woff") format("woff"); 
        font-style: normal;
        font-weight: normal;
    }
}

@mixin icon($content: none) {
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    font-family: icon;
    line-height: 1;
    text-transform: none;
    speak: none;
    content: $content;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon:before {
    @include icon;
}

.icon-arrow-down:before { content: $icon-arrow-down; }
.icon-arrow-left:before { content: $icon-arrow-left; }
.icon-arrow-list:before { content: $icon-arrow-list; }
.icon-arrow-right:before { content: $icon-arrow-right; }
.icon-check:before { content: $icon-check; }
.icon-person:before { content: $icon-person; }
.icon-play:before { content: $icon-play; }
