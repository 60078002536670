﻿$footer-background-color: $primary;
$footer-padding: rem(72px) 0 0;

@import '~bulma/sass/layout/footer';

footer {
	color: $primary-alt;
	font-size: rem(18px);
	font-family: $family-title;

	a {
		color: $primary-alt;

		&:hover {
			color: #fff;
		}
	}

	.title {
		color: #fff;
	}

	.columns:last-child {
		margin-bottom: rem(60px);
	}

	$colors: "purple" $purple $purple-alt, "yellow" $yellow $yellow-alt, "blue" $blue $blue-alt;

	@each $name, $color, $color-alt in $colors {
		.#{$name} & {
			background: $color;
			color: $color-alt;

			a {
				color: $color-alt;
			}

			.title {
				color: #fff;
			}
		}
	}
}