﻿.checkbox,
.dataconsent {
	position: relative;
	text-align: left;

	input {
		position: absolute;
		margin: 0;
		top: 0;
		left: 0;
		opacity: 0;

		&:checked + label {

			&:before {
				color: $primary;
			}
		}
	}

	label {
		position: relative;
		display: block;
		padding: 0 0 0 rem(36px);
		margin: 0;
		cursor: pointer;
		margin: 0 0 rem(19px);
		font-size: rem(16px);

		&:before {
			@include icon($icon-check);
			position: absolute;
			width: rem(20px);
			height: rem(20px);
			padding: rem(4px) 0 0;
			top: rem(8px);
			left: 0;
			text-align: center;
			background: #fff;
			font-size: 13px;
			color: transparent;
		}

		&:hover:before {
			color: rgba($primary, 0.5);
		}

		p {
			margin: 0;
		}

		a {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	$colors: "purple" $purple $purple-alt $purple-light, "yellow" $yellow $yellow-alt $yellow-light, "blue" $blue $blue-alt $blue-light;

	@each $name, $color, $color-alt, $color-light in $colors {
		.#{$name} & {

			input:checked + label {

				&:before {
					color: $color;
				}
			}

			label:hover:before {
				color: rgba($color, 0.5);
			}
		}
	}
}
