﻿.logo {
	z-index: 101;
	display: block;
	width: rem(212px);
	height: rem(150px);
	margin: 0 0 rem(40px);
	text-indent: -9999px;
	background: url(../../images/primary/logo-blaak-en-breederveld-primary.png) no-repeat center;
	background-size: contain;

	$colors: "purple" $purple, "yellow" $yellow, "blue" $blue;

	@each $name, $color in $colors {
		.#{$name} & {
			background: url(../../images/#{$name}/logo-blaak-en-breederveld-#{$name}.png) no-repeat center;
			background-size: cover;
		}
	}
}
