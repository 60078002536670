﻿@import '~bulma/sass/layout/hero';

.hero {
	display: flex;
	background: $primary url(../../../images/primary/hero-image-primary.jpg) no-repeat 100% -50px;
	//min-height: rem(400px);

	.hero-body {
		display: flex;
		padding: 0;
	}

	.container {
		display: flex;
		align-items: center;
		align-content: center;
		flex-wrap: wrap;
		padding-top: rem(58px);
		padding-bottom: rem(59px);
	}

	.title {
		color: #fff;
		width: 100%;
		line-height: rem(56px);
		margin: 0 0 rem(34px);
	}

	.breadcrumb {
		position: absolute;
		bottom: rem(28px);
		left: rem(20px);
	}

	&.small {
		min-height: rem(267px);

		.title {

			@include fullhd {
				width: 50%;
			}
		}
	}

	.purple & {
		background: $purple url(../../../images/purple/hero-image-purple.jpg) no-repeat 100% -40px;
	}

	.yellow & {
		background: $yellow url(../../../images/yellow/hero-image-yellow.jpg) no-repeat 100% -70px;
	}

	.blue & {
		background: $blue url(../../../images/blue/hero-image-blue.jpg) no-repeat 100% -80px;
	}
}