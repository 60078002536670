﻿$card-content-padding: rem(33px) rem(20px) rem(28px);
$card-shadow: 0 0.5em 1em -0.125em rgba($primary, 0.1), 0 0px 0 1px rgba($primary, 0.02);

@import '~bulma/sass/components/card';

.card {

	.subtitle {
		color: $primary;
		margin: 0 0 rem(10px);
	}

	.card-content {

		@include tablet {
			padding: rem(33px) rem(39px) rem(28px);
		}

		@include desktop {
			padding: rem(33px) rem(20px) rem(28px);
		}

		@include fullhd {
			padding: rem(33px) rem(39px) rem(28px);
		}
	}

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	$colors: "purple" $purple $purple-alt $purple-light, "yellow" $yellow $yellow-alt $yellow-light, "blue" $blue $blue-alt $blue-light;

	@each $name, $color, $color-alt, $color-light in $colors {
		.#{$name} & {
			box-shadow: 0 .5em 1em -.125em rgba($color, 0.1), 0 0 0 1px rgba($color, 0.02);

			.subtitle {
				color: $color;
			}
		}
	}
}