﻿.shortcut {
	position: relative;

	.title {
		@include font-title-24;
		color: $primary;
		font-weight: normal;
		padding: rem(37px) rem(40px) rem(36px);
		margin: 0;
		background: #fff;

		strong {
			font-weight: 700;
		}


		$colors: "purple" $purple $purple-alt, "yellow" $yellow $yellow-alt, "blue" $blue $blue-alt;

		@each $name, $color in $colors {
			.#{$name} & {
				color: $color;
			}
		}
	}

	.button {
		position: absolute;
		bottom: rem(40px);
		left: rem(40px);
	}

	&:hover {

		.button {
			@include button-hover;
		}
	}
}
